import { render, staticRenderFns } from "./dy-list.vue?vue&type=template&id=627793de&scoped=true"
import script from "./dy-list.vue?vue&type=script&lang=js"
export * from "./dy-list.vue?vue&type=script&lang=js"
import style0 from "./dy-list.vue?vue&type=style&index=0&id=627793de&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627793de",
  null
  
)

export default component.exports