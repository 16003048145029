<template>
    <div class="view">
        <header id="fx-header">
            <div class="more" @click.stop="goEdit()"><i class="hwiconfont icon-shezhi"></i></div>
        </header>
        <div class="header flex-c" v-if="data.id">
            <!-- <img class="header-img" :src="rImg(data.cover)" onerror="this.src='../../assets/u_bg.png'" alt=""> -->
            <img class="header-img" src="../../assets/u_bg.png" alt="">
        </div>
        <div class="user-container">
            <div class="container-box">
                <div class="tx-user">
                    <div class="user">
                        <img class="tx" @click.stop="goEdit()" :src="rImg(data.head_portrait)" :onerror="txImg" alt="">
                        <div class="num-box">
                            <div class="l-box">
                                <p class="num">{{ returnNum(data.attention) }}</p>
                                <p class="label">粉丝</p>
                            </div>
                            <div class="l-box">
                                <p class="num">{{ returnNum(data.following) }}</p>
                                <p class="label">关注</p>
                            </div>
                            <div class="l-box">
                                <p class="num">{{ returnNum(data.count_zan) }}</p>
                                <p class="label">获赞</p>
                            </div>
                        </div>
                    </div>
                    <div class="u-wrap" @click.stop="goEdit()">
                        <div class="username" style="position: relative;flex-wrap: wrap;">
                            {{data.name}}
                            <span class="new-auth" v-if="data.auth_tag" style="margin-left: 5px;">{{data.auth_tag}}</span>
                            <!-- <span class="chat-lab" v-if="data.is_chat_discount">{{data.is_chat_discount}}</span> -->
                        </div>
                        <p class="small-txt" v-if="data.remarkname">昵称：{{data.name}}</p>
                        <p class="small-txt">ID：{{data.id}} <i style="color: #aaa;" class="hwiconfont icon-dingdan"></i></p>
                    </div>
                    <p class="small-txt" v-if="data.slogan" style="color: #333;">{{data.slogan}}</p>
                    <p class="small-txt" v-else @click.stop="goEdit()">点击添加介绍，让大家更了解你 <i class="hwiconfont icon-bianji"></i></p>
                    <div class="lab-box" @click.stop="goEdit()">
                        <span style="margin-right: 7px;" class="boy-tag" v-if="data.age" :class="{'girl-tag':data.sex == '女'}">{{data.age}}</span>
                        <span class="lab" v-if="data.city"><i class="hwiconfont icon-weizhi"></i>{{data.city}}</span>
                    </div>
                    <p class="ban-tip" v-if="data.banned_tip">{{data.banned_end_time ? '冻结账号至'+data.banned_end_time : '账号已冻结'}}{{(data.configPersonal && data.configPersonal.is_read_rule == 1) ? '[到期自动解封]':'[点击申请解封]'}}</p>
                    <!-- expertise   专业认证的标签 experience  经验认证的标签 -->
                    <div class="rz-box" v-if="data.expertise && data.expertise.length && data.configPersonal && data.configPersonal.hide_expertise!=1">
                        <span>擅长:</span>
                        <span v-for="(m, index) in data.expertise" v-if="index<rzShow.sc" class="rz"><i class="hwiconfont icon-renzheng"></i>{{m}}</span>
                        <span @click.stop="rzShow.sc=data.expertise.length" class="rz-more" v-show="data.expertise.length>rzShow.sc"><i class="hwiconfont icon-xiangxia"></i></span>
                    </div>
                    <div class="rz-box" v-if="data.experience && data.experience.length && data.configPersonal && data.configPersonal.hide_experience!=1">
                        <span>经验:</span>
                        <span v-for="(m, index) in data.experience" v-if="index<rzShow.exp" class="rz"><i class="hwiconfont icon-renzheng"></i>{{m}}</span>
                        <span @click.stop="rzShow.exp=data.experience.length" class="rz-more" v-show="data.experience.length>rzShow.exp"><i class="hwiconfont icon-xiangxia"></i></span>
                    </div>
                    <div class="bottom" style="position: relative;">
                        <div class="experience-box" v-if="data.is_experience!=1">你应该有很多生活的经验<br>快来认证一下</div>
                        <div class="btn-box">
                            <button class="gz-btn ready" @click="showApp()">钱包</button>
                            <button class="gz-btn ready" @click="showApp()">经验/专长</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fx-nav">
                <div class="nav-box">
                    <ul class="nav-ul">
                        <li class="list" :class="{'active':index==nav}" v-for="(m, index) in navList" @click.stop="setNav(index)">{{m.title}}({{m.num}})</li>
                    </ul>
                    <!-- <i :class="{'new-invisible':nav==0}" class="hwiconfont icon-clean" @click.stop="clearAll()"></i> -->
                </div>
                <div class="small-nav" v-show="navList[nav].cNav">
                    <ul class="nav-ul">
                        <li class="list" :class="{'active':index==navList[nav].nav}" v-for="(m, index) in navList[nav].cNav" @click.stop="setCNav(index)">{{m.title}}</li>
                        <!-- <i v-show="nav == 1" class="hwiconfont icon-sousuo" onclick="_url({text:'输入关键词搜索', types:'collect'}, 'mfjrame0/search_case', true)"></i> -->
                    </ul>
                </div>
            </div>
            <dyList :list="navList[nav].list" h5="1" v-infinite-scroll="getData"></dyList>
            <p class="more-btn" v-if="navList[nav].list.length">{{navList[nav].isNext==0 ? '暂无更多':'加载中'}}</p>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    import dyList from "@components/common/dy-list";
    export default {
        components: {
            dyList,
        },
        data() {
            return {
                data: {},
                rzShow: {},
                nav: 0,
                navList: [{
                    title: '作品',
                    list: [],
                    cNav: [{
                        title: '全部',
                        param: {}
                    }, {
                        title: '合编',
                        param: {
                            // hebian: 1,
                            furl: 'api/Experience/hebian_records'
                        }
                    }],
                    nav: 0,
                    isNext: 1,
                    load: 0,
                    num: 0
                }, {
                    title: 'GET',
                    list: [],
                    cNav: [{
                        title: '经验',
                        param: {
                            source_type: 5,
                            lover: 1
                        }
                    }, {
                        title: '作品',
                        param: {
                            source_type: 0,
                            lover: 1
                        }
                    }, {
                        title: '服务',
                        ios: 1,
                        param: {
                            source_type: 3,
                            lover: 1
                        }
                    }],
                    nav: 0,
                    isNext: 1,
                    load: 0,
                    num: 0,
                }, {
                    title: '历史',
                    list: [],
                    cNav: [{
                        title: '已购买',
                        ios: 1,
                        param: {
                            subscribe: 1,
                            readed: 1
                        }
                    }, {
                        title: '全部',
                        param: {
                            readed: 1
                        }
                    }],
                    nav: 1,
                    isNext: 1,
                    load: 0,
                    num: 0,
                }, {
                    title: '赞',
                    list: [],
                    isNext: 1,
                    num: 0,
                    load: 0,
                    param: {
                        zaned: 1
                    }
                }],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.initData();
        },
        methods: {
            // ...mapMutations([])
            async initData() {
                this.data = await this.$y_getUser();
                await this.getData(1);
            },
            goEdit() {
                this.to_url('h5sz')
            },
            setNav(i) {
                if (this.nav == i) return;
                this.nav = i;
                this.getData(1);
            },
            setCNav(i) {
                if (this.navList[this.nav].nav == i) return;
                this.navList[this.nav].nav = i;
                this.getData(1);
            },
            async getData(first) {
                let myid = this.$y_getKey("userid");
                let param = {
                    longitude: this.$y_getKey("lon"),
                    latitude: this.$y_getKey("lat"),
                    type: 0, // 动态类型
                    user_id: myid || 8,
                };
                let info = this.navList[this.nav];
                if (this.nav == 0) {
                    param.userid = myid;
                }
                if (info.cNav && info.cNav[info.nav] && info.cNav[info.nav].param) {
                    param = Object.assign(param, info.cNav[info.nav].param)
                } else {
                    param = Object.assign(param, info.param)
                }
                if (first) {
                    param.page = 1;
                }
                let furl = 'api/dymanic/lists';
                if (param.furl) {
                    furl = param.furl;
                    delete param.furl;
                }
                let {
                    list,
                    total
                } = await this.$y_list(furl, info.list, param);
                info.list = list;
                if (total && total != -1) {
                    info.isNext = total == info.list.length ? 0 : 1;
                } else {
                    info.isNext = total == -1 ? 0 : 1;
                }
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .new-auth {
        background: #f3f3f3;
        border-radius: 3px;
        padding: 2px 5px;
        display: inline-flex;
        align-items: center;
        font-size: 12px;

        &::before {
            color: #fd5621;
            font-family: "hwiconfont";
            content: "\e7fc";
            font-size: 16px;
        }
    }

    #fx-header {
        background: transparent;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        text-align: right;

        .more {
            padding: 10px;

            .hwiconfont {
                font-weight: 700;
                color: #fff;
                font-size: 20px;
            }
        }

        .title {
            opacity: 0;
            box-sizing: border-box;
            height: auto;
            text-align: center;

            .name {
                font-size: 16px;
                line-height: 50px;
            }
        }
    }

    .header-img {
        width: 100vw;
        height: 150px;
        object-fit: cover;
    }

    .user-container {
        background: #fff;
        margin-top: -20px;
        border-radius: 16px 16px 0 0;
        position: relative;
        z-index: 1;

        .container-box {
            padding: 15px 16px 0;
        }

        .tx-user {
            margin-top: -40px;

            .user {
                display: flex;
                margin-bottom: 10px;
                align-items: flex-end;

                .tx {
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    border: 3px solid #fff;
                }

            }

            .username {
                font-size: 16px;
                display: flex;
                align-items: center;
                font-weight: bold;
            }

            .small-txt {
                font-size: 12px;
                margin-top: 5px;
                color: #aaa;
            }

            .num-box {
                flex: 1;
                display: flex;
                justify-content: space-around
            }

            .l-box {
                text-align: center;
                margin-right: 15px;
                font-size: 12px;

                &:last-child {
                    margin-right: 0;
                }

                .num {
                    font-size: 18px;
                    font-weight: bold;
                }

            }


            .rz-box {
                margin-bottom: 5px;

                .rz {
                    color: #fd5621;
                    background: #FEEDE8;
                    font-size: 12px;
                    border-radius: 3px;
                    margin-left: 5px;
                    padding: 0 2px;
                }

                .hwiconfont {
                    color: #fd5621;
                    font-size: 12px;
                }
            }



            .lab-box {
                margin: 5px 0;

                .boy-tag {
                    background-color: #eee;
                    color: #333;
                    border-radius: 3px;
                }

                .lab {
                    background-color: #eee;
                    color: #333;
                    font-size: 12px;
                    border-radius: 3px;
                    padding: 0 3px;

                    .hwiconfont {
                        font-size: 12px;
                    }
                }

            }
        }
    }

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;

        .gz-btn {
            background: #eee;
            color: #000;
            border: 0;
            padding: 0;
            width: 45%;
            text-align: center;
            line-height: 35px;
            border-radius: 25px;
        }
    }

    #fx-nav {
        z-index: 1;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background: #fff;
        box-sizing: border-box;

        .nav-box {
            display: flex;
            align-items: center;

            .list {
                width: 25%;
                text-align: center;
                padding: 7px 0 0px;
                font-size: 13px;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &::after {
                    display: inline-block;
                    content: '';
                    border-radius: 3px;
                    width: 7px;
                    height: 3px;
                    margin: 3px 0 5px;
                }

                &.active {
                    &::after {
                        background: #000;
                    }
                }
            }
        }

        .nav-ul {
            display: flex;
            overflow-x: auto;
            flex: 1;

        }
    }

    /* 子栏目 */
    .small-nav {
        padding: 0 10px 10px;

        .list {
            width: auto;
            background: #fafafa;
            color: #aaa;
            padding: 7px 15px;
            border-radius: 5px;
            margin-right: 7px;
            font-size: 12px;

            &.active {
                background: #eee;
                color: #000;
            }
        }
    }

    .experience-box {
        color: #fff;
        background: #fd5621;
        border-radius: 3px;
        padding: 3px 5px;
        position: absolute;
        right: 10px;
        text-align: center;
        font-size: 10px;
        top: -50px;

        &::after {
            display: inline-block;
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid #fd5621;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            position: absolute;
            bottom: -8px;
        }
    }

    .ban-tip {
        font-size: 12px;
        margin-top: 7px;
        color: red;
    }
</style>