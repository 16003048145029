import { render, staticRenderFns } from "./my.vue?vue&type=template&id=52b3f2c8&scoped=true"
import script from "./my.vue?vue&type=script&lang=js"
export * from "./my.vue?vue&type=script&lang=js"
import style0 from "./my.vue?vue&type=style&index=0&id=52b3f2c8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b3f2c8",
  null
  
)

export default component.exports