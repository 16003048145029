<template>
    <div :class="{'marginTop':!h5}">
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <el-row v-else>
            <div class="flag-list" v-for="j in 3">
                <el-col class="flag-list-box" v-if="index%3==j-1" v-for="(m, index) in list">
                    <a href="javascript:;" @click.stop="getLink(m)">
                        <el-skeleton :loading="loading" animated :count="1">
                            <template slot="template">
                                <el-skeleton-item variant="image" class="img" />
                                <div class="content">
                                    <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                                    <el-skeleton-item variant="text" style="width: 50%;" />
                                </div>
                                <div class="bottom flex-bt">
                                    <el-skeleton-item variant="text" style="width: 30%;" />
                                    <el-skeleton-item variant="text" style="width: 30%;" />
                                </div>
                            </template>
                            <template>
                                <div class="bottom">
                                    <div class="user">
                                        <img :src="rImg(m.avatar)" :onerror="txImg" class="tx" alt="" />
                                        <span class="name ellipsis-1">{{m.name}}</span>
                                        <span class="exp-lab" v-if="m.charmName"><i class="iconfont icon-renzheng"></i>{{m.charmName}}</span>
                                    </div>
                                </div>
                                <div class="flag-box">
                                    <div class="content">
                                        <div class="title ellipsis-2">{{ clearHtml(m.content) }}</div>
                                    </div>
                                    <div v-if="m.video" class="video-img">
                                        <img class="v-img" :src="rImg(m.cover)" alt="">
                                    </div>
                                    <div class="img-box" v-else-if="m.masterimg && m.masterimg.length">
                                        <img class="pic" :src="rImg(p)" v-if="pi<6" v-for="(p, pi) in m.masterimg" :onerror="errImg" alt="">
                                        <!-- <el-image class="img" :class="{ 'video-img': m.video }" :src="getImg(m)" fit="cover">
                                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                        </el-image> -->
                                    </div>
                                </div>
                                <p class="sh-tag" v-if="m.shenhe != undefined && m.shenhe != 1">{{m.shenhe==-1 ?'未通过。原因：'+m.reason:'审核中'}}</p>
                                <div class="topic-wrap" v-if="m.topics">
                                    <div class="topic-box">
                                        <span class="topic" v-if="ti<3" v-for="(t, ti) in m.topics">{{t.name}} · {{t.view}}浏览<i class="el-icon-arrow-right"></i></span>
                                    </div>
                                    <!-- <div class="topic-box">
                                        <span class="topic" v-if="ti<3" v-for="(t, ti) in m.topic">{{t}}</span>
                                    </div> -->
                                </div>
                                <div class="comment-box" v-if="m.hot_comment">
                                    <div class="tx-box">
                                        <div class="name-box" v-if="m.hot_comment.user">
                                            <img class="tx" :src="rImg(m.hot_comment.user.head_portrait)" :onerror="txImg" alt="">
                                            <span class="name">{{m.hot_comment.user.name}}</span>
                                            <span class="hot-lab">热评</span>
                                        </div>
                                        <div class="zan" :class="{'active':m.hot_comment.is_digg}">
                                            <span>{{m.hot_comment.digg_count}}</span>
                                            <i class="iconfont icon-dianzan"></i>
                                        </div>
                                    </div>
                                    <div class="txt">{{m.hot_comment.content}}</div>
                                </div>
                                <div class="comment-box" v-else-if="m.title">
                                    <div class="tx-box">
                                        <div class="name-box">
                                            <img class="tx" :src="rImg(m.avatar)" :onerror="txImg" alt="">
                                            <span class="name">{{m.name}}</span>
                                            <span class="hot-lab">作者</span>
                                        </div>
                                    </div>
                                    <div class="txt">{{m.title}}</div>
                                </div>
                                <div class="bottom flex-bt bottom-iconbox">
                                    <div class=" flex">
                                        <i class="new-sc"></i>
                                        <span class="see">{{m.lover}}</span>
                                    </div>
                                    <div class=" flex">
                                        <i class="iconfont icon-dianzan"></i>
                                        <span class="see">{{ m.thumbsup }}</span>
                                    </div>
                                    <div class=" flex">
                                        <i class="iconfont icon-xiaoxi"></i>
                                        <span class="see">{{ m.comments ? m.comments :'评论' }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-skeleton>
                    </a>
                </el-col>
            </div class="flag-list">
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'dyList',
        components: {},
        props: ['list', 'h5'],
        data() {
            return {
                loading: false
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // 获取图片
            getImg(m) {
                let img = m.video ? m.cover : m.masterimg[0];
                return this.rImg(img);
            },
            // 打开详情
            getLink(m) {
                let u = '';
                if (m.shenhe != undefined && m.shenhe != 1) {
                    this.$y_msg(m.shenhe == -1 ? '未通过' : '审核中')
                    return;
                }
                switch (m.source_type) {
                    case 1:
                    case 2:
                        u = '/flagdes?id=' + m.source_id;
                        break;
                    case 3:
                        u = '/fwdes?id=' + m.source_id;
                        break;
                    case 4:
                        u = '/actdes?id=' + m.source_id;
                        break;
                    default:
                        u = '/oridydes?id=' + m.dy_id;
                        break;
                }
                if (this.h5) {
                    window.location.href = '#/h5des?id=' + m.dy_id;
                    // window.open(window.location.hostname + '#/h5des?id=' + m.dy_id, '_blank');
                } else {
                    window.location.href = '#' + u;
                }
                // return u;
            }
        },
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';

    .marginTop {
        margin-top: 15px;

    }

    .flag-list {
        width: calc((100% - 45px) / 3);
        border: 0;
        margin-right: 15px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        .flag-list-box {
            margin-bottom: 15px;
            background: #fafafa;
            border-radius: 15px;
        }

        .content {
            padding: 0 10px;

        }

        .bottom {
            border: 0;
        }

        .video-img {
            width: 100%;
            display: block;
            position: relative;
            margin-top: 15px;
            min-height: 100px;

            &::after {
                position: absolute;
                left: calc(50% - 20px);
                top: calc(50% - 20px);
            }

            .v-img {
                width: 100%;
                max-height: 300px;
                object-fit: cover;
            }
        }

        .img-box {
            display: inline-flex;
            flex-wrap: wrap;
            margin-top: 15px;
            padding-left: 10px;

            .pic {
                width: 100px;
                height: 100px;
                border-radius: 15px;
                margin-right: 10px;
                margin-bottom: 10px;
                object-fit: cover;
            }
        }


        .exp-lab {
            margin-left: 5px;
            color: #fd5621;
            background: #FEEDE8;
            font-size: 0.6rem;
            padding: 3px 5px;
            border-radius: 15px;

            .iconfont {
                color: #fd5621;
                margin-right: 3px;
                font-size: 0.6rem;
            }
        }
    }


    .img-box {
        position: relative;

        .tag {
            position: absolute;
            top: 0px;
            left: 0px;
            background: red;
            color: #fff;
            padding: 5px;
        }
    }




    .topic-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;


        .topic-box {
            display: inline-flex;
            align-items: flex-start;
            flex-direction: column;
            /* margin-right: 10px;
            flex-wrap: wrap; */

            .topic {
                background: #FEEDE8;
                padding: 3px;
                margin-bottom: 5px;
                border-radius: 5px;
                font-size: 0.6rem;
                margin-right: 7px;

                &::before {
                    display: inline-block;
                    content: '#';
                    color: #E33026;
                    margin-right: 3px;
                    font-size: 0.7rem;
                    font-weight: bold;
                }
            }
        }
    }



    .comment-box {
        background: #f3f3f3;
        padding: 7px;
        border-radius: 5px;
        margin: 0 10px;
        word-break: break-all;
        white-space: normal;

        p {
            font-size: 0.6rem;
            margin-bottom: 5px;
            color: #333;

            &:last-child {
                margin-bottom: 0;
            }

        }

        .name {
            font-weight: bold;
            font-size: 0.6rem;
            /* color: #757575; */
            margin-right: 7px;
        }

        .txt {
            padding: 10px;
        }


        .tx-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tx {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                margin-right: 7px;
            }

            .name-box {
                display: inline-flex;
                align-items: center;
            }

            .hot-lab {
                background: #FEEDE8;
                color: #fd5621;
                padding: 3px;
                border-radius: 5px;
                margin-left: 5px;
                font-size: 0.5rem;
            }
        }
    }

    .sh-tag {
        padding: 10px;
        color: #fd5621;
    }


    /* 移动端 */
    @media (max-width: 600px) {
        .el-row {
            display: block;
        }


        .flag-list {
            width: calc(100% - 30px);
            margin: 15px;

            .img-box {
                .pic {
                    width: calc((100vw - 70px) / 3);
                    height: calc((100vw - 70px) / 3);
                }
            }
        }
    }
</style>